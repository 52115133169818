import Box, { BoxProps } from '@mui/material/Box';

import { styled } from '@mui/material/styles';

import subHeaderBackground from '../../../../assets/svgs/sub-header-background.icon.svg';
import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';

const StyledMainContainer = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'relative',
  background: `url(${subHeaderBackground}) right no-repeat`,
  backgroundSize: 'auto 100%',
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.customShadows.subHeader
}));

interface StyledContainerProps extends BoxProps {
  nested?: boolean;
}
const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'nested'
})<StyledContainerProps>(({ nested, theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '4rem 3.2rem 2.4rem',
  '& h1': {
    color: theme.palette.primary,
    ...theme.typography.h1,
    marginTop: '4px',
    marginRight: '16px',
    marginBottom: 0
  },
  '& p': {
    ...theme.typography.subtitle1,
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(3),
    letterSpacing: '0.5px',
    color: theme.palette.text.secondary
  },

  [theme.breakpoints.down('sm')]: {
    padding: '1rem 3.2rem'
  }
}));

const StyledSkeleton = styled(Skeleton)<SkeletonProps>(({ theme }) => ({
  width: '300px',
  height: '30px',
  backgroundColor: theme.palette.action.hover,
  marginRight: '16px',
  marginLeft: '8px'
}));

const StyledButton = styled(LoadingButton)<LoadingButtonProps>(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
    // maxWidth: 'unset',
    whiteSpace: 'no-wrap'
  },
  // flex: 1,
  width: 'auto',
  // maxWidth: '32%',
  whiteSpace: 'pre-wrap',
  marginTop: '3px',
  maxHeight: '56px',
  '& .MuiButton-startIcon': {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    marginLeft: 0
  }
}));

const StyledTabsContainer = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  // position: 'absolute',
  bottom: '0px',
  // height: '74px',
  borderBottom: `1px solid ${theme.palette.grey[200]}`
}));

const StyledTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    maxWidth: '40rem'
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: '100rem'
  },
  // position: 'absolute',
  bottom: '-1px',
  maxHeight: '38px',
  marginLeft: theme.spacing(4),
  '& .MuiTab-root': {
    color: theme.palette.grey[400],
    fontWeight: 500,
    '&.Mui-selected': {
      fontWeight: 700
    }
  },
  '& .MuiTabs-indicator': {
    height: '3px'
  }
}));

const StyledTab = styled(Tab)<TabProps>(({ theme }) => ({
  padding: '10px 12px 16px 12px',
  whiteSpace: 'nowrap'
}));

export {
  StyledMainContainer,
  StyledContainer,
  StyledSkeleton,
  StyledButton,
  StyledTabsContainer,
  StyledTabs,
  StyledTab
};
